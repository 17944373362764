const isOpen = ref(false);

export const useContactRequestForm = () => {
    const open = () => {
        isOpen.value = true;
    };

    return {
        isOpen,
        open,
    };
};
